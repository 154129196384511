import React from "react";

export default function ContractedIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M7.32234 12.0106H8.57355C8.82948 12.0194 8.98588 12.0811 9.04276 12.1958C9.09015 12.2795 9.11385 12.403 9.11385 12.5661V14.0212C9.11385 14.1623 9.04749 14.2967 8.91479 14.4246C8.51668 14.8082 7.91951 15 7.12328 15C6.4171 15 5.80335 14.7531 5.28201 14.2593C4.76067 13.761 4.5 13.1614 4.5 12.4603C4.5 11.7593 4.76541 11.175 5.29623 10.7077C5.82704 10.2359 6.45265 10 7.17305 10C7.7323 10 8.26075 10.172 8.75839 10.5159C8.88635 10.6041 8.95034 10.7033 8.95034 10.8135C8.95034 10.9193 8.89583 11.0428 8.78683 11.1839C8.60199 11.4175 8.43374 11.5344 8.28207 11.5344C8.19203 11.5344 8.04747 11.4749 7.84842 11.3558C7.64936 11.2324 7.41239 11.1706 7.1375 11.1706C6.7773 11.1706 6.45739 11.2897 6.17776 11.5278C5.89814 11.7615 5.75832 12.0701 5.75832 12.4537C5.75832 12.8329 5.90051 13.157 6.18487 13.4259C6.46924 13.6905 6.79152 13.8228 7.15172 13.8228C7.41239 13.8228 7.64699 13.7831 7.85553 13.7037V12.9828H7.2939C7.14698 12.9828 7.04271 12.9652 6.9811 12.9299C6.91949 12.8946 6.8792 12.8439 6.86024 12.7778C6.84128 12.7072 6.83181 12.6146 6.83181 12.5C6.83181 12.381 6.84128 12.2862 6.86024 12.2156C6.88394 12.1451 6.92422 12.0966 6.9811 12.0701C7.06641 12.0304 7.18015 12.0106 7.32234 12.0106Z" fill="currentColor" />
            <path d="M11.0253 10.0794L12.3405 10.086C13.0277 10.086 13.632 10.3175 14.1533 10.7804C14.6746 11.239 14.9353 11.8056 14.9353 12.4802C14.9353 13.1504 14.6794 13.7257 14.1675 14.2063C13.6604 14.6869 13.0443 14.9272 12.3191 14.9272H11.0182C10.7291 14.9272 10.549 14.8721 10.4779 14.7619C10.421 14.6693 10.3926 14.5282 10.3926 14.3386V10.6614C10.3926 10.5556 10.3949 10.4784 10.3997 10.4299C10.4091 10.3814 10.4328 10.3241 10.4708 10.2579C10.5371 10.1389 10.7219 10.0794 11.0253 10.0794ZM12.3405 13.7566C12.6817 13.7566 12.9898 13.6398 13.2647 13.4061C13.5395 13.168 13.677 12.8704 13.677 12.5132C13.677 12.1561 13.5419 11.8585 13.2718 11.6204C13.0064 11.3779 12.6936 11.2566 12.3334 11.2566H11.6509V13.7566H12.3405Z" fill="currentColor" />
            <path d="M15.8957 14.2526C15.7724 14.1116 15.7108 13.9859 15.7108 13.8757C15.7108 13.761 15.8127 13.6111 16.0165 13.4259C16.135 13.3201 16.2606 13.2672 16.3933 13.2672C16.526 13.2672 16.7108 13.3774 16.9478 13.5979C17.0142 13.6728 17.109 13.7456 17.2322 13.8161C17.3554 13.8823 17.4691 13.9153 17.5734 13.9153C18.0142 13.9153 18.2346 13.7478 18.2346 13.4127C18.2346 13.3113 18.173 13.2275 18.0497 13.1614C17.9312 13.0908 17.782 13.0401 17.6019 13.0093C17.4218 12.9784 17.2274 12.9299 17.0189 12.8638C16.8104 12.7932 16.616 12.7116 16.436 12.619C16.2559 12.5265 16.1042 12.381 15.981 12.1825C15.8625 11.9797 15.8032 11.7372 15.8032 11.455C15.8032 11.067 15.9573 10.7297 16.2653 10.4431C16.5781 10.1521 17.0023 10.0066 17.5379 10.0066C17.8222 10.0066 18.0805 10.0419 18.3128 10.1124C18.5497 10.1786 18.7133 10.2469 18.8033 10.3175L18.981 10.4431C19.128 10.571 19.2014 10.679 19.2014 10.7672C19.2014 10.8554 19.1445 10.9766 19.0308 11.131C18.8697 11.3514 18.7038 11.4616 18.5332 11.4616C18.4336 11.4616 18.3104 11.4175 18.1635 11.3294C18.1493 11.3205 18.1208 11.2985 18.0782 11.2632C18.0403 11.228 18.0047 11.1993 17.9715 11.1772C17.872 11.1199 17.744 11.0913 17.5876 11.0913C17.436 11.0913 17.308 11.1265 17.2037 11.1971C17.1042 11.2632 17.0544 11.358 17.0544 11.4815C17.0544 11.6005 17.1137 11.6975 17.2322 11.7725C17.3554 11.8474 17.5071 11.8981 17.6872 11.9246C17.8673 11.9511 18.0639 11.9929 18.2772 12.0503C18.4905 12.1032 18.6872 12.1693 18.8673 12.2487C19.0474 12.328 19.1967 12.4669 19.3152 12.6653C19.4384 12.8593 19.5 13.0996 19.5 13.3862C19.5 13.6728 19.4384 13.9264 19.3152 14.1468C19.1919 14.3629 19.0308 14.5304 18.8317 14.6495C18.4478 14.8832 18.0379 15 17.6019 15C17.3791 15 17.1682 14.9757 16.9691 14.9272C16.7701 14.8743 16.6089 14.8104 16.4857 14.7354C16.2345 14.5944 16.0592 14.4577 15.9596 14.3254L15.8957 14.2526Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M17 3.5H7C5.067 3.5 3.5 5.067 3.5 7V17C3.5 18.933 5.067 20.5 7 20.5H17C18.933 20.5 20.5 18.933 20.5 17V7C20.5 5.067 18.933 3.5 17 3.5ZM7 2C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2H7Z" fill="currentColor" />
        </svg>
    )
}
